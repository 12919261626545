import { Project } from '~/types/project';

export default function storage (project: Project) {
  let make: string = '';
  let model: string = '';
  if (project?.storage?.length === 1) {
    make = project?.storage[0]?.make ?? '';
    model = project?.storage[0]?.model ?? '';
  } else {
    make = 'Multiple';
    model = 'Multiple';
  } 

   // Calculate wattage and discharge storageSums
   const storageSums = project?.storage?.reduce(
    (acc, storage) => {
      acc.wattage += storage?.wattage ?? 0;
      acc.discharge += storage?.discharge ?? 0;
      return acc;
    },
    { wattage: 0, discharge: 0 }
  );

  const wattage = storageSums?.wattage;
  const discharge = storageSums?.discharge;

  //Build storage string for portfolio summary table
  let storageDisplay: string = 'N/A'; 
  if (storageSums && !(storageSums.wattage === 0 && storageSums.discharge === 0)) {
    storageDisplay = `${storageSums.wattage ?? 'N/A'} / ${storageSums.discharge ?? 'N/A'}`;
  }
  
  return {
    storageDisplay,
    make,
    model,
    wattage,
    discharge,
  };
}