import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useProjectContext } from '~/hooks';
import logo from '~/assets/conductor-avatar-transparent.png';
import { GridContainer } from '~/components/GridComponent';
import { GridTableEl } from '~/components/GridComponent';
import { GridLabelEl } from '~/components/GridComponent';
import { PricingType, PricingEstimateAutopricing } from '~/constants';
import SavingsAnalysisTable from '../SavingsAnalysis/SavingsAnalysisTable';
import PricingEstimateQuoteButton from './PricingEstimateQuoteButton';

const PricingEstimateTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--2x-small);
  margin-bottom: var(--small);
`;

export default function PricingEstimateQuoteTable() {
  const { project, projectAutopricing } = useProjectContext();
  const [isPriceEstimateStale, setIsPriceEstimateStale] = React.useState(false);

  useEffect(() => {
    if (project) {
      setIsPriceEstimateStale(!!(project.priceEstimateTimestamp && project.priceEstimateTimestamp < project.updatedAt));
    }
  }, [project.priceEstimateTimestamp, project.updatedAt]);

  return (
    <>
      <div style={{ marginBottom: 'var(--2x-large)' }}>
        <PricingEstimateTableHeader>
          <h2 style={{ margin: '0'}}>Pricing estimate</h2>
          <img src={logo} style={{ width: '3.5rem', height: '3.5rem' }} alt='' />
        </PricingEstimateTableHeader>
        <GridContainer italic={isPriceEstimateStale} columnValues="1fr 1fr">
          {PricingEstimateAutopricing[project?.pricingType]?.map(({ label, valueKey }) => (
            <React.Fragment key={`${project?.pricingType}-${label}`}>
              <GridTableEl>{label}</GridTableEl>
              <GridLabelEl textAlign='right'>{projectAutopricing?.autopricingResult?.[valueKey]}</GridLabelEl>
            </React.Fragment>
          ))}
          <GridTableEl gridColumn='1 / -1' style={{ marginTop: 'var(--x-small)'}}>
            <PricingEstimateQuoteButton isPriceEstimateStale={isPriceEstimateStale} />
          </GridTableEl>
        </GridContainer>
      </div>
      {project?.pricingType === PricingType.OFFTAKE && <SavingsAnalysisTable isPriceEstimateStale={isPriceEstimateStale} />}
    </>
  );
}