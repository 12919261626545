export const CompanyOwnership = {
  OWNER: 'OWNER',
  SOLAR_EXPERIENCE: 'SOLAR_EXPERIENCE',
  THIRD_PARTY_EXPERIENCE: 'THIRD_PARTY_EXPERIENCE',
};

export const CompanyOwnershipDisplay = {
  OWNER: 'Owner',
  SOLAR_EXPERIENCE: 'Relevant experience',
  THIRD_PARTY_EXPERIENCE: 'Customer tier',
};

export const CompanyOwnershipDropdownOptions = [
  { key: 'A', text: 'A', value: 'A' },
  { key: 'B', text: 'B', value: 'B' },
  { key: 'C', text: 'C', value: 'C' }
];