import { ProductionUnit } from '~/constants';
import { nonEmptyString, range, validFloat, validationChain, areRecStateFieldsEnabled, isIllinoisShinesProgram, isNewJerseyQuote } from '../validators';
import { additionalLineItemSchema } from '../util';
import { Project } from '~/types/project';

const validation = {
  addressState: nonEmptyString,
  pricingType: nonEmptyString,  
  projectArrays: [{
    type: nonEmptyString,
    sizeKwdc: validationChain(validFloat, range(0, 40000)),
    sizeKwac: validationChain(isIllinoisShinesProgram(validFloat), range(0, 40000)),
    yearOneProduction: (value: number, project: Project, index: number) => {
      const isValidFloat = validFloat(value);
      const unit = project?.projectArrays?.[index]?.yearOneProductionUnit;
      if (isValidFloat) {
        return true;
      } else if (unit === ProductionUnit.KWH && value < 5000) {
        return 'Must be at least 5,000 kWh.';
      } else if (unit === ProductionUnit.KWHKW) {
        return range(500, 3000)(value);
      }
      return false;
    },
  }],
  epcCost: validFloat, 
  contractTerm: validFloat,
  contractRate: validFloat,
  contractEscalator: validFloat,
  upfrontIncentives: validFloat,
  yearOneSavings: validFloat,
  avoidedCostOfSolar: validFloat,
  propertyTaxType: nonEmptyString,
  propertyTaxAmount: validFloat,
  propertyTaxEscalator: validFloat,
  additionalRevenues: [additionalLineItemSchema],
  additionalOperatingExpenses: [additionalLineItemSchema],
  recOwnership: isNewJerseyQuote(nonEmptyString),
  recsData: {
    'Illinois': {
      isShinesProgram: areRecStateFieldsEnabled('Illinois', nonEmptyString),
      recAllocation: isIllinoisShinesProgram(nonEmptyString),
      recProjectCategory: isIllinoisShinesProgram(nonEmptyString),
      recUtilityGroup: isIllinoisShinesProgram(nonEmptyString),
      recProgramYear: isIllinoisShinesProgram(nonEmptyString)
    },
    'New Jersey': {
      recAllocation: areRecStateFieldsEnabled('New Jersey', nonEmptyString),
      recValue: areRecStateFieldsEnabled('New Jersey', validationChain(validFloat, range(1, 500))),
      recContractTerm: areRecStateFieldsEnabled('New Jersey', validFloat)
    }
  },
};

export default validation;