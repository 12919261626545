import { useState } from 'react';
import { useProjectContext } from '~/hooks';
import { GridContainer } from '~/components/GridComponent';
import { GridTableEl } from '~/components/GridComponent';
import { GridLabelEl } from '~/components/GridComponent';
import { PricingEstimateProposalType } from '~/constants';
import PricingEstimateProposalButton from './PricingEstimateProposalButton';
import CustomerProposalModal from '~/modals/CustomerProposalModal';

export default function SavingsAnalysisTable({ isPriceEstimateStale }: { isPriceEstimateStale?: boolean }) {
  const { project, projectAutopricing, projectState } = useProjectContext();
  const [modalOpen, setModalOpen] = useState(false);

  type ProposalButtonType = 'DEFAULT' | 'PPA' | 'LEASE';
  const projectProposalType = project?.customerProposal?.proposalType as ProposalButtonType;
  const [proposalButtonType, setProposalButtonType] = useState<ProposalButtonType>(projectProposalType ? projectProposalType : 'DEFAULT');

  const handleClick = (newState: ProposalButtonType) => {
    if (proposalButtonType === 'DEFAULT') {
      setModalOpen(true);
    }
    setProposalButtonType(newState);
  };

  return (
    <>
      <h2>Savings analysis</h2>
      {(project?.contractType === 'PPA' || projectState?.isQuote) && (
        <div style={{ marginBottom: 'var(--medium)' }}>
          
          <GridContainer columnValues='1fr 1fr' style={{ marginBottom: 'var(--small)'}}>
          
            <GridTableEl bold>Net savings</GridTableEl>
            <GridLabelEl textAlign='right'>{projectAutopricing?.netSavingsPercentageRange?.netSavingsRange}</GridLabelEl>
            
            <GridTableEl gridColumn='1 / -1' bold>PPA</GridTableEl>
            
            <GridTableEl>Current pricing</GridTableEl>
            <GridLabelEl textAlign='right'>{projectAutopricing?.currentPricing?.ppaCurrentPricing}</GridLabelEl>
            
            <GridTableEl>Solar contract</GridTableEl>
            <GridLabelEl textAlign='right'>{projectAutopricing?.solarContract?.ppaSolarContract}</GridLabelEl>
            
            <hr style={{ gridColumn: '1 / -1'}}></hr>
            
            <GridTableEl>Net savings</GridTableEl>
            <GridLabelEl textAlign='right'>{projectAutopricing?.netSavingsValueRange?.ppaNetSavings}</GridLabelEl>
            
            {projectState?.isQuote && (
              <GridTableEl gridColumn='1 / -1' style={{ marginTop: 'var(--x-small)'}}>
                {(proposalButtonType === 'DEFAULT' || proposalButtonType === 'PPA') && (
                  <PricingEstimateProposalButton
                    isPriceEstimateStale={!!isPriceEstimateStale} 
                    proposalType={PricingEstimateProposalType.PPA}
                    onClick={() => handleClick('PPA')}
                  />
                )}
              </GridTableEl>
            )}
          
          </GridContainer>
        </div>
      )}
      {(project?.contractType === 'LEASE' || project?.contractType === 'ENERGY_SERVICE_AGREEMENT') && (
        <div style={{ marginBottom: 'var(--medium)' }}>
          <GridContainer columnValues='1fr 1fr' style={{ marginBottom: 'var(--small)'}}>
            
            <GridTableEl gridColumn='1 / -1' bold>Lease</GridTableEl>
            
            <GridTableEl>Current pricing</GridTableEl>
            <GridLabelEl textAlign='right'>{projectAutopricing?.currentPricing?.leaseCurrentPricing}</GridLabelEl>
            
            <GridTableEl>Solar contract</GridTableEl>
            <GridLabelEl textAlign='right'>{projectAutopricing?.solarContract?.leaseSolarContract}</GridLabelEl>

            <hr style={{ gridColumn: '1 / -1'}}></hr>
            
            <GridTableEl>Net savings</GridTableEl>
            <GridLabelEl textAlign='right'>{projectAutopricing?.netSavingsValueRange?.leaseNetSavings}</GridLabelEl>
            
            {projectState?.isQuote && (
              <GridTableEl gridColumn='1 / -1' style={{ marginTop: 'var(--x-small)'}}>
                {(proposalButtonType === 'DEFAULT' || proposalButtonType === 'LEASE') && (
                  <PricingEstimateProposalButton 
                    isPriceEstimateStale={!!isPriceEstimateStale}
                    proposalType={PricingEstimateProposalType.LEASE} 
                    onClick={() => handleClick('LEASE')}
                  />
                )}
              </GridTableEl>
            )}
        
        </GridContainer>
        </div>
      )}
      {modalOpen && 
        <CustomerProposalModal 
          closeModal={() => setModalOpen(false)}
          proposalType={proposalButtonType === 'PPA' ? PricingEstimateProposalType.PPA : PricingEstimateProposalType.LEASE} 
          setProposalButtonType={setProposalButtonType}
        />
      }
    </>
  );
}