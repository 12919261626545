import axios from '~/utils/axiosInstance';

export const updateCompanyName = async function (companyId: string, name: string) {
   const response = await axios.patch(`/company/${companyId}`, {name});
   return response.data;
};

export const getCompany = async function (companyId: string) {
   const response = await axios.get(`/company/${companyId}`);
   return response.data;
};