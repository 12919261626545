import { Icon, Popup } from 'semantic-ui-react';

export default function PricingEstimateTooltip() {
  return (
   <Popup
      position="bottom center"
      style={{ fontSize: '14px', minWidth: '300px' }}
      trigger={<Icon name="info circle" size="large" style={{ color: 'var(--color-charcoal' }} tabIndex={0} />}
      content={
      <div style={{ maxWidth: '300px' }}>
         <strong style={{ fontSize: '16px' }}>Estimated Pricing</strong>
         <p style={{ color: '#FFFFFF', margin: '1em 0em' }}>Conductor’s estimated pricing is based on our marketplace data and recent transactions. We have found it to be directionally correct for the middle market in the US and highly accurate for projects that meet the following criteria:</p>
         <ul>
            <li>NTP transaction with contracted offtake</li>
            <li>Behind the meter interconnection</li>
            <li>Tier 1 equipment for key components</li>
            <li>Solar PV without battery storage</li>  
            <li>Production estimate aligned with independent engineering</li>
         </ul>
      </div>
      }          
      inverted
      on={['hover', 'focus']}
   />
  );
}