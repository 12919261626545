import { Checkbox, Form } from 'semantic-ui-react';
import { CheckboxContainer } from '~/components/form/styles';
import { useProjectContext } from '~/hooks';
import { ItcAdders } from '~/types';

export default function ItcAddersForm() {
  const { project, updateProjectValue, config, projectState } = useProjectContext();
  const itcAddersList: ItcAdders = config?.itcAdders ?? [];
  const selectedItcAdders: ItcAdders = project?.itcAdders ?? [];

  const formGroupStyles = projectState.isQuote ? 
    {
      paddingBottom: '0',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      rowGap: '0.75rem',
      maxHeight: '4rem',
    } :
    { paddingBottom: '0' };

  return (
    <CheckboxContainer>
        <Form.Field className="label">
          <label 
            style={ 
              projectState.isQuote ? { 
                fontSize: '0.875rem', 
                fontWeight: '700', 
                fontStyle: 'normal', 
                lineHeight: '1.25rem', 
                padding: '0'
              } : {}
            }
          >
            ITC adders
          </label>
          {!projectState.isQuote && <p>Select all that apply.</p>}
        </Form.Field>
        <Form.Group style={formGroupStyles}>
          {itcAddersList.map((itcAdder: any) => {
            const isSelected = !!selectedItcAdders.find((selectedItcAdder: any) => selectedItcAdder.id === itcAdder.id);
            return (
              <Form.Field key={itcAdder.id} style={projectState.isQuote ? { margin: 0 } : {}}>
                <Checkbox
                  checked={isSelected}
                  label={`${itcAdder.label} (${itcAdder.value}%)`}
                  onChange={() => {
                    if (isSelected) {
                      // Remove the ITC adder
                      updateProjectValue('', { 
                        itcAdders: selectedItcAdders.filter(adder => adder.id !== itcAdder.id) 
                      }, { patch: true });
                    } else {
                      // Add the ITC adder
                      updateProjectValue('', { 
                        itcAdders: [...selectedItcAdders, itcAdder] 
                      }, { patch: true });
                    }
                  }}
                />
              </Form.Field>
            );
          })}
        </Form.Group>
      </CheckboxContainer>
  );
}