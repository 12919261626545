import { useProjectContext } from '~/hooks';
import { useDerivedSavingsAutocalculateFields } from '~/features/projectWizard/derived/useDerivedSavingsAutocalculateFields';
import { WorksheetField } from '../common/components/Field';
import { InputType } from '~/schema';
import { Unit, UnitDecimals } from '~/constants';

export default function PricingEstimateYearOneSavings() {
  const { project } = useProjectContext();

  // Update avoided cost of solar / year one savings when either of the fields change
  useDerivedSavingsAutocalculateFields();

  return (
    <div className='radio-selections'>
      <WorksheetField
        type="number"
        fieldLabel="Year one utility bill savings"
        schemaKey={project?.yearOneSavingsUnit === Unit.YEAR ? 'yearOneSavings' : 'avoidedCostOfSolar'}
        numberType={InputType.DOLLARS}
        decimals={project?.yearOneSavingsUnit === Unit.YEAR ? UnitDecimals.YEAR_ONE_SAVINGS : UnitDecimals.KWH}
        unitField='yearOneSavingsUnit'
        units={[
          { value: Unit.YEAR, label: 'per year' },
          { value: Unit.KWH, label: 'per kWh' },
        ]}
        defaultUnit={Unit.YEAR}
      />
    </div>
  );
};