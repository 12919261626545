import { ProjectArraysShortDisplay } from '~/constants';
import { formatNumber } from '~/utils/formatNumber';
import { display } from '~/utils/displayValues/common';
import { Project } from '~/types/project';

export default function technologies(project: Project) {
  // Create a map of array types and their kWdc sum
  const projectArraysMap: Record<string, number> = Object.values(project?.projectArrays || {})
    .reduce((acc, array) => {
      if (!acc[array.type]) {
        acc[array.type] = 0;
      }
      acc[array.type] += (array.sizeKwdc ?? 0);
      return acc;
  }, {} as Record<string, number>);
  
  const kwdcSum = `${formatNumber(Object.values(projectArraysMap).reduce((acc: number, number) => acc + number, 0))} kWdc`; // Sum of all kWdc 

  const technologiesList = Object.entries(projectArraysMap)
    .sort((a, b) => b[1] - a[1]) // Sort based on size descending
    .filter(([type]) => ProjectArraysShortDisplay[type]) // Keep only entries that have a valid display
    .map(([type, size]) => `${formatNumber(size)} kWdc, ${ProjectArraysShortDisplay[type]}`);

  let technologies: string = '';
  if (technologiesList.length > 1) {
    // If there are multiple arrays, display the sum of kWdc and the technologies
    technologies += `${kwdcSum} (${technologiesList.join(', ')})`;
  } else if (technologiesList.length === 1) {
    // If there is only one array, display that array's kWdc and the technology
    technologies += technologiesList[0];
  }
      
  // Calculate wattage and discharge storageSums
  const storageSums = project?.storage?.reduce(
    (acc, storage) => {
      acc.wattage += storage?.wattage ?? 0;
      acc.discharge += storage?.discharge ?? 0;
      return acc;
    },
    { wattage: 0, discharge: 0 }
  );

  // Build the storage details string
  const storageDetails = [
    storageSums?.wattage ? `${formatNumber(storageSums.wattage)} kW` : 'N/A',
    storageSums?.discharge ? `${formatNumber(storageSums.discharge)} kWh` : 'N/A'
  ].join(' / ');

  // If there is storage, add it to the technologies string
  if (project?.hasStorage && (project?.storage?.find(row => row.wattage || row.discharge))) {
    if (technologies) {
      technologies += '; '; // Prefix with ';' if there are other technologies
    }
    technologies += `${storageDetails}, Storage`;
  }

  const arrayTypes = display(technologies);

   return {
    arrayTypes,
    kwdcSum 
  };
}