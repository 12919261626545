import axios from '~/utils/axiosInstance';
import externalAxiosInstance from 'axios';
import { Dataroom } from '~/types/dataroom';

interface CustomDocTypeParams {
  categoryId: string;
  name: any;
  description: string;
  isUserProvidedName?: boolean;
}

interface editDocumentTypeParams {
  dataroomId: string;
  documentTypeId: any;
  data?: any;
  name?: string;
  description?: string;
  isSelected?: boolean;
  isCustom?: boolean;
  isUserProvidedName?: boolean;
}

interface ToggleProjectDocumentTypeParams {
  dataroomId?: string;
  documentTypeId?: any;
  isSelected?: boolean;
  isCustom?: boolean;
}

interface DownloadFileBundleParams {
  id: string;
  fileType: string;
  dateStr: string;
}

export async function createDocumentType(id: string, { categoryId, name, description, isUserProvidedName = true }: CustomDocTypeParams) {
  const response = await axios.post(`/dataroom/${id}/document-types`, { categoryId, name, description, isUserProvidedName});
  return response.data;
}

export function fetchProjectDataroom(id: string) {
  return axios.get(`/projects/${id}/dataroom`).then(res => res.data as Dataroom);
}

export function fetchPortfolioDataroom(id: string) {
  return axios.get(`/portfolios/${id}/dataroom`).then(res => res.data as Dataroom);
}

export async function fetchDataroomUploadUrl(id: string, fileName: string, documentTypeId: any, isCustom: boolean, isUserProvidedName: boolean = true) {
  const response = await axios.get(
    `/dataroom/${id}/upload-url?fileName=${encodeURIComponent(fileName)}&documentTypeId=${documentTypeId}&isCustom=${isCustom}&isUserProvidedName=${String(isUserProvidedName)}`,
  );
  return response.data;
}

export async function copyProjectDoclist(dataroomId: string, projectIdToCopy: string) {
  const response = await axios.put(`dataroom/${dataroomId}/document-types/copy/project/${projectIdToCopy}`);
  return response.data;
}

export async function editDocumentType({ dataroomId, documentTypeId, ...data }: editDocumentTypeParams) {
  const response = await axios.patch(`/dataroom/${dataroomId}/document-types/${documentTypeId}`, data);
  return response.data;
}

export async function deleteDocumentType(dataroomId: string, documentTypeId: string) {
  const response = await axios.delete(`/dataroom/${dataroomId}/document-types/${documentTypeId}`);
  return response.data;
}

export async function toggleProjectDocumentType({ dataroomId, documentTypeId, isSelected, isCustom }: ToggleProjectDocumentTypeParams = {}) {
  const response = await axios.patch(`/dataroom/${dataroomId}/document-types/${documentTypeId}`, {
    isSelected,
    isCustom,
  });
  return response.data;
}

export async function sendFileMetadata(id: string, metadata: any) {
  const response = await axios.post(`dataroom/${id}/documents`, metadata);
  return response.data;
}

export async function updateFileMetadata(id: string, docId: any, metadata: any) {
  const response = await axios.put(`dataroom/${id}/documents/${docId}`, metadata);
  return response.data;
}

export async function downloadFile({id, docId}: {id: string; docId: any}) {
  const response = await axios.get(`/dataroom/${id}/documents/${docId}/download-url`);
  if (response?.data?.presignedUrl) {
    // Navigate to the presigned S3 url to trigger the download
    window.location = response.data.presignedUrl;
  }
}

export async function downloadFileBundle({id, fileType, dateStr}: DownloadFileBundleParams) {
  const response = await axios.get(`/dataroom/${id}/download-url?fileType=${fileType}&dateStr=${dateStr}`);
  return response?.data?.presignedUrl;
}

export async function deleteFile({id, docId}: {id: string; docId: any}) {
  const response = await axios.delete(`/dataroom/${id}/documents/${docId}`);
  return response.data;
}

export async function uploadFile(args: any) {
  const { id, documentTypeId, fileData, isCustom, isUserProvidedName, isReplace } = args;
  const nameSplit = fileData.name.split('.');
  const uploadUrl = await fetchDataroomUploadUrl(id, fileData.name, documentTypeId, isCustom, isUserProvidedName);
  const s3Key = uploadUrl.filePath;
  await externalAxiosInstance.put(uploadUrl.presignedUrl, fileData);

  const needsDocumentNameUpdate = isReplace && isCustom && isUserProvidedName === false;

  const metadataResponse = args.docId
    ? await updateFileMetadata(id, args.docId, {
        fileType: nameSplit[nameSplit.length - 1],
        s3Key,
        documentTypeId,
        isCustom,
        investorApproved: false,    // Always reset approval status when replacing a document
      })
    : await sendFileMetadata(id, {
      fileType: nameSplit[nameSplit.length - 1],
      s3Key,
      documentTypeId,
      isCustom,
    });

  if (needsDocumentNameUpdate) {
    await editDocumentType({
      dataroomId: args.id,
      documentTypeId: documentTypeId,
      name: fileData.name,
      isCustom: true,
      isSelected: true
    });
  }
  
  // Return the metadata response as well as the new document name if applicable
  return { 
    ...metadataResponse,
     newDocumentName: needsDocumentNameUpdate ? fileData.name : undefined
  };
}

export async function setDocumentApprovalStatus(id: string, docId: any, approved: boolean) {
  const response = await axios.put(`/dataroom/${id}/documents/${docId}/${approved ? 'approve' : 'unapprove'}`);
  return response.data;
}

export const getInvestorProjectDataroomAccess = async function (id: string) {
  const response = await axios.get(`/projects/${id}/dataroom/investorAccess`);
  return response.data;
};

export const getInvestorPortfolioDataroomAccess = async function (id: string) {
  const response = await axios.get(`/portfolios/${id}/dataroom/investorAccess`);
  return response.data;
};

export const grantInvestorDataroomAccess = async function (id: string, investorId: string) {
  const response = await axios.put(`/dataroom/${id}/investorAccess/${investorId}`);
  return response.data;
};

export const revokeInvestorDataroomAccess = async function (id: string, investorId: string) {
  const response = await axios.delete(`/dataroom/${id}/investorAccess/${investorId}`);
  return response.data;
};