export const CreatePriceEstimateType = {
  OFFTAKE: 'OFFTAKE',
  ACQUISITION: 'ACQUISITION',
};

export const CreatePriceEstimateTypeDisplay = {
  [CreatePriceEstimateType.OFFTAKE]: 'PPA',
  [CreatePriceEstimateType.ACQUISITION]: 'Project value: EPC + dev fee'
};

export const CreatePricingEstimateRadioOptions = [
  {
    label: CreatePriceEstimateTypeDisplay[CreatePriceEstimateType.OFFTAKE],
    value: CreatePriceEstimateType.OFFTAKE
  },
  {
    label: CreatePriceEstimateTypeDisplay[CreatePriceEstimateType.ACQUISITION],
    value: CreatePriceEstimateType.ACQUISITION
  }
];

export const CreatePricingEstimateDropdownOptions = [
  {
    key: CreatePriceEstimateType.OFFTAKE,
    text: CreatePriceEstimateTypeDisplay[CreatePriceEstimateType.OFFTAKE],
    value: CreatePriceEstimateType.OFFTAKE
  },
  {
    key: CreatePriceEstimateType.ACQUISITION,
    text: CreatePriceEstimateTypeDisplay[CreatePriceEstimateType.ACQUISITION],
    value: CreatePriceEstimateType.ACQUISITION
  }
];


export const PricingEstimateType = {
  KWH: 'KWH',
  YEAR_ONE_PAYMENT: 'YEAR_ONE_PAYMENT',
  TOTAL: 'TOTAL',
  WDC: 'WDC',
};

export const PricingEstimateTypeUnitDisplay = {
  [PricingEstimateType.KWH]: 'kWh',
  [PricingEstimateType.YEAR_ONE_PAYMENT]: 'year',
  [PricingEstimateType.TOTAL]: 'year',
  [PricingEstimateType.WDC]: 'Wdc'
};

export const PricingEstimateProposalType = {
  PPA: 'PPA',
  LEASE: 'LEASE'
};

export const PricingEstimateProposalTypeDisplay = {
  [PricingEstimateProposalType.PPA]: 'PPA',
  [PricingEstimateProposalType.LEASE]: 'Lease'
};
