import { PricingType } from '~/constants';
import { display } from '~/utils/displayValues/common';
import { formatDollars } from '~/utils/formatNumber';
import { UnitDecimals } from '~/constants';
import { Project } from '~/types/project';

export default function totals(project: Project, projectCosts: any) {
  // ITC projects should show the total minus the conductor fee
  const totalProp = project.pricingType === PricingType.ITC ? 'totalMinusFee' : 'total';

  if (project?.pricingType === PricingType.ACQUISITION && projectCosts?.bid === undefined) {
    return {
      perWdc:'To bid',
      total: 'TBD',
      perWdcMinusFee: 'TBD',
      totalMinusFee: 'TBD'
    };
  }
  return {
    perWdc: display(
      projectCosts?.perWdc?.[totalProp] && formatDollars(projectCosts.perWdc[totalProp], { dec: UnitDecimals.WDC, default0: '' }),
      'To bid'
    ),
    total: display(
      projectCosts?.overall?.[totalProp] && formatDollars(projectCosts.overall[totalProp], { dec: UnitDecimals.TOTAL, default0: '' }),
      'TBD'
    ),
    perWdcMinusFee: display(
      projectCosts?.perWdc?.totalMinusFee && formatDollars(projectCosts.perWdc.totalMinusFee, { dec: UnitDecimals.WDC, default0: '' }),
      'TBD'
      ),
    totalMinusFee: display(
      projectCosts?.overall?.totalMinusFee && formatDollars(projectCosts.overall.totalMinusFee, { dec: UnitDecimals.TOTAL, default0: '' }),
      'TBD'
      )
  };
}