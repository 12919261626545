import { Project, ProjectArray, ProjectStorage } from '~/types/project';
import { formatNumber } from '~/utils/formatNumber';

export default function technologies(portfolioProjects: any = []) {
  const projectSizeKwdc = portfolioProjects.reduce((sum: number, project: Project) => {
    const projectArrays = Object.values(project?.projectArrays || {});

   projectArrays.forEach((projectArray: ProjectArray) => {
      if (projectArray.sizeKwdc) {
        sum += projectArray.sizeKwdc;
      }
    });
    return sum;
  }, 0 as number);

  const totalSizeKwdc = projectSizeKwdc ? `${formatNumber(projectSizeKwdc)} kWdc` : '— kWdc' as string;

  const projectStorage = portfolioProjects?.reduce(
    (acc: { discharge: number; wattage: number }, project: Project) => {
       if (project?.hasStorage && Array.isArray(project?.storage)) {
        project.storage.forEach((storage: ProjectStorage) => {
          if (storage?.wattage && storage?.discharge) {
            acc.wattage += storage?.wattage;
            acc.discharge += storage?.discharge;
          }
        });
      }
      return acc;
    },
    { discharge: 0, wattage: 0 }
  );

  const storage = (projectStorage?.wattage > 0 && projectStorage.discharge > 0) ? `${formatNumber(projectStorage?.wattage)} kW / ${formatNumber(projectStorage?.discharge)} kWh` : '— kW / — kWh' as string;
 
  return {
    totalSizeKwdc,
    storage
  };
}