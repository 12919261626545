import * as S from './style';
import { usePortfolioContext } from '~/hooks';
import PortfolioTheme from '~/components/PortfolioTheme';
import PortfolioSummaryTable from './components/PortfolioSummaryTable';
import { DealStates } from '~/constants';
import DealSummary from './components/DealSummary';
import AdminPortfolioInvestorInvites from '../admin/AdminPortfolioInvestorInvites';
import { usePermissions } from '~/requests/permissions/usePermissions';
import OwnershipPicker from '~/components/OwnershipPicker';
import Timestamp from '~/components/Timestamp';
import useAnalyticsPageView from '~/hooks/analytics/useAnalyticsPageView';
import PortfolioSummary from './components/PortfolioSummary';
import { Header as SemanticHeader } from 'semantic-ui-react';

export default function PortfolioReview() {
  const { portfolio, portfolioState } = usePortfolioContext();
  const { permissions } = usePermissions();

  const isInvestor = !permissions.isAdmin && permissions.hasPortfolioInvestorAccess;
  const showPortfolioSummary = (isInvestor && portfolioState?.latestState >= DealStates.AWAITING_BID) || portfolioState?.latestState >= DealStates.PROJECT_HAS_ERRORS;
  const showDealSummary = !isInvestor && (portfolioState?.latestState >= DealStates.IN_REVIEW && portfolioState?.latestState < DealStates.IN_DILIGENCE);

  // Analytics
  useAnalyticsPageView('/review', 'Review');

  return (
    <div style={{ paddingBottom: 'var(--x-large)' }}>
      <PortfolioTheme>
        <div style={{ display: 'flex', alignItems: 'center', paddingTop: 'var(--x-small)' }}>
          {permissions.isAdmin && <OwnershipPicker />}
          <div><Timestamp portfolio={portfolio} /></div>
        </div>
        
        <SemanticHeader style={{ fontSize: 'var(--medium)', fontWeight: 700, paddingBottom: 'var(--medium)', paddingTop: 'var(--small)', margin: 0 }} as='h2'>Key details</SemanticHeader>
        <PortfolioSummary />
        {permissions.isAdmin && <AdminPortfolioInvestorInvites />} 
        
        {showDealSummary && (
          <S.DealSummaryContainer>
            <DealSummary />
          </S.DealSummaryContainer>
        )}
        
        {showPortfolioSummary && (
          <S.SummaryTableContainer>
            <PortfolioSummaryTable />
          </S.SummaryTableContainer>
        )}
      
      </PortfolioTheme>
    </div>
  );
}

