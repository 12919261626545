import { PricingType } from './pricing-types';

export const PricingEstimateAutopricing = {
  [PricingType.OFFTAKE]: [
    { label: 'PPA rate, year 1', valueKey: 'ppaResult' },
    { label: 'Total year 1 payments', valueKey: 'leaseResult' },
  ],
  [PricingType.ACQUISITION]: [
    { label: 'EPC cost + dev fee', valueKey: 'epcResult' },
    { label: '$ per Wdc', valueKey: 'epcWdcResult' },
  ],
};

// This is the only place where the display text should be changed as this data is tied to the autopricing spreadsheet
export const ProjectAutopricing = {
  [PricingType.OFFTAKE]: [
    { label: 'Expected year 1 payment', valueKey: 'leaseResult' },
    { label: '$ per kWh', valueKey: 'ppaResult' },
    
  ],
  [PricingType.ACQUISITION]: [
    { label: 'EPC cost + dev fee', valueKey: 'epcResult' },
    { label: '$ per Wdc', valueKey: 'epcWdcResult' },
  ],
};


