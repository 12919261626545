import { useForm, usePromiseWatcher, useUserContext } from '~/hooks';
import * as S from './styles';
import { FormSchemaAwareWidget } from '~/components/form';
import { withForm } from '~/utils/withForm';
import { useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { getCompany, updateCompanyName } from '~/api/company';
import { Form } from '../../styles';
import { Company } from '~/types/company';
import { Loader } from 'semantic-ui-react';

const defaults: { companyName: string } = { companyName: '' };

const CompanyNameForm = withForm({ defaults }, () => {
   const { loggedInUser } = useUserContext();
   const { formState } = useForm();
   const [fetchedCompany, setFetchedCompany] = useState({} as Company);
   const [loading, setLoading] = useState(!fetchedCompany.name);

   const { execute: executeUpdateCompanyName } = usePromiseWatcher(
      () => updateCompanyName(fetchedCompany.id ?? '', formState.companyName),
      {messageStub: 'updating company name'},
      [formState]
    );

   // Get fresh company name (loggedInUser in session does not update when name is edited) 
   useEffect(() => {
      populateCompanyName();
   }, []);

   const populateCompanyName = () => {
      const userCompanyId = loggedInUser.company?.id ?? loggedInUser.installer?.companyId ?? loggedInUser.investor?.companyId;
      
      if (userCompanyId) {
         getCompany(userCompanyId)
            .then((fetchedCompany) => { 
               setFetchedCompany(fetchedCompany);
               setLoading(false); 
            });
      }
   };

   defaults.companyName = fetchedCompany.name;

   // Auto-save user info
   const formStateRef = useRef(formState);
   const autoSaveCompanyNameTimeoutRef = useRef();
   useEffect(() => {
      // Only autosave if the value actually changed
      if (formState.companyName && !isEqual(formState, formStateRef.current)) {

      // Clear any existing timeout so tons of saves don't happen for each key stroke
      if (autoSaveCompanyNameTimeoutRef.current) {
         clearTimeout(autoSaveCompanyNameTimeoutRef.current);
      }

      // Set a timeout to call the execute function after 3 seconds
      autoSaveCompanyNameTimeoutRef.current = setTimeout(executeUpdateCompanyName, 3000) as any;

      // Update the formState ref
      formStateRef.current = formState;
      }
   }, [formState]);

   return (
      <S.CompanySettingsContainer>
         {loading ? 
            <Loader active style={{ position: 'relative' }} size="small" />
            : 
            <Form>
               <FormSchemaAwareWidget
                  schemaKey="companyName"
                  fluid
                  fieldLabel="Company Name"
                  fieldLabelStyle={{ fontSize: '1rem' }}
                  fieldStyle={{ marginTop: 'var(--x-small)' }}
                  placeholder="Enter Company Name"
               />
            </Form>}
      </S.CompanySettingsContainer>
   );
});

export default CompanyNameForm;