import styled from 'styled-components';

export const Header = styled.p`
  font-size: 1rem;
  font-weight: 700;
  color: var(--color-black);
  margin: 0;
  margin-bottom: var(--x-small);
`;

export const LogoManagement = styled.div`
  display: flex;
  align-items: center;
  gap: var(--large);
  max-width: 100%;
`;

export const LogoMessage = styled.p`
  font-size: 0.875rem;
  max-width: 28rem;
  width: 100%;
  color: var(--color-charcoal);
`;

export const RemoveUserButton = styled.button`
  &&& {
    all: unset;
    height: 3rem;
    display: flex;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    &:hover,
    &:focus-visible {
      background: var(--color-grey);
      cursor: pointer;
    }
    &:focus-visible {
      outline: 2px solid var(--color-secondary);
    }
    > i {
      height: 1rem;
      line-height: 1rem; 
      margin: 0 !important;
      color: var(--color-red);
    }
  }
`;

export const CompanySettingsContainer = styled.div`
  &&& {
    input {
      margin: 0;
      margin-top: var(--x-small);
    }
  }
`;
