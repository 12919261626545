import styled from 'styled-components';

// Custom CSS grid component 
// This is set up to use Subgrid for future use, see comments below

interface GridElementProps {
  gridColumn?: string;
  columnValues?: string | null;
  backgroundColor?: string;
  maxWidth?: string;
  textAlign?: string;
  primary?: boolean;
  charcoal?: boolean;
  black?: boolean;
  bold?: boolean;
  lineHeight?: string;
  italic?: boolean;
};


export const GridContainer = styled.div<GridElementProps>`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => props.columnValues};
  grid-row-gap: var(--2x-small);
  font-style: ${props => props.italic ? 'italic' : 'normal'};
 
  * {
    padding: 0;
    margin: 0;
  }

  .title {
    color: var(--color-pure-black);
    font-weight: 700;
  }
`;

//Coupled with GridContainer, this component isn't strictly necessary without subgrid
//It's here for the current styling setup AND future use
export const GridTable = styled.div<GridElementProps>`
  grid-column: ${props => props.gridColumn}; 
  padding-bottom: var(--medium);

  width: 100%;
  display: grid;
  grid-template-columns: ${props => props.columnValues};
  //Delete line above  and uncomment line below for future subgrid use
  /* grid-template-columns: ${props => props.columnValues || 'subgrid'}; */

  ${props => props.lineHeight ? `line-height: ${props.lineHeight};` : ''}

  .line {
    grid-column: 1 / -1;
    border-bottom: 1px solid var(--color-gray);
    width: 100%;
    height: 0;
  }  
`;

export const GridLabelEl = styled.div<GridElementProps>`
  font-size: .875rem;
  font-weight: 700;
  color: var(--color-charcoal);
  color: ${props => props.black ? 'var(--color-black)' : 'var(--color-charcoal)'};
  text-align: ${props => props.textAlign};
  line-height: inherit;
  //A background color is not currently used, but could be added
  /* background-color: ${props => props.backgroundColor}; */
  padding: var(--x-small) var(--x-small) var(--x-small) 0;
  grid-column: ${props => props.gridColumn};
  &.total {
    text-decoration: none;
    color: var(--color-pure-black);
  }
`;

export const GridTableEl = styled.div<GridElementProps>`
  font-size: .875rem;
  text-align: ${props => props.textAlign};
  color: ${props => props.primary ? 'var(--color-primary)' : props.charcoal ? 'var(--color-charcoal)' : 'var(--color-pure-black)'};
  font-weight: ${props => props.bold ? 'bold' : 'inherit'}; 
  line-height: inherit;
  background-color: ${props => props.backgroundColor};
  padding: var(--x-small) var(--2x-small) var(--x-small) 0;
  
  grid-column: ${props => props.gridColumn};
`;

export const SubGrid = styled.div<GridElementProps>`
  max-width: ${props => props.maxWidth || '100%'};
  grid-column: ${props => props.gridColumn}; 
  padding-bottom: var(--medium);

  display: grid;
  grid-template-columns: ${props => props.columnValues};
  //Delete line above  and uncomment line below for future subgrid use
  /* grid-template-columns: ${props => props.columnValues || 'subgrid'}; */

  * {
    padding: 0;
    margin: 0;
  }

  h3 {
    font-size: .875rem;
    text-decoration: underline;
  }
  
  p, strong, .title {
    font-size: .875rem;
    font-weight: 700;
  }

  p, strong {
    justify-self: end;
    padding-right: var(--2x-small);
  }

  .line {
    grid-column: 1 / -1;
    border-bottom: 1px solid black;
    width: 100%;
    height: 0;
    margin: var(--x-small) 0;
  } 

  .estimate {
    grid-column: 2 / 4;
  }
`;